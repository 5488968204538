;(function ($) {

    // список аудио файлов
    var sounds = {
        'empty-trash' : 'empty-trash.ogg',
        'error' : 'error.mp3',
        'success' : 'success.ogg',
        '1' : '1.ogg',
        '2' : '2.ogg',
        '3' : '3.ogg',
        '4' : '4.ogg',
        '5' : '5.ogg',
        '7' : '7.mp3',
        '9' : '9.mp3',
        '10' : '10.mp3',
    };

    'use strict';
     
    /**
     * Сам класс с методами для работы с плагином
     *
     * все изменения должны делаться именно здесь
     */
    var Notification = function (elm, options) {
        var self,
            init = function () {
            
            },

            /**
             * Уведомление об ошибке
             * 
             * params.title = Заглавие
             * params.text = Текст
             *
             * https://sciactive.com/pnotify/
             */
            error = function (params) {
                
                var options = $.extend(true,  {
                    type: 'error',
                    addclass: 'alert alert-styled-left bg-danger-300'
                }, params);
                
                _pNotify(options);

                $.fn.playSound(sounds[options.type]);
            },

            /**
             * Уведомление об успешной операции
             * 
             * params.title = Заглавие
             * params.text = Текст
             *
             * https://sciactive.com/pnotify/
             */
            success = function (params) {
                var options = $.extend(true, {
                    type: 'success',
                    addclass: 'alert alert-styled-left bg-success-300'
                }, params);

                _pNotify(options);

                $.fn.playSound(sounds[options.type]);
            },

            /**
             * Всплывапщее окно с текстом
             * 
             * params.title = Заглавие
             * params.text = Текст
             *
             * http://t4t5.github.io/sweetalert/
             * https://limonte.github.io/sweetalert2/
             */
            alert = function (params) {
                var options = $.extend(true, {
                    title: 'Ошибка',
                    type: 'error',
                    confirmButtonText: 'Закрыть'
                }, params);

                swal(options);

                $.fn.playSound(sounds[options.type]);
            },

            // pNotify уведомления
            _pNotify = function (options) {
                new PNotify(options);
            };
          
        self = {
            init: init,
            error: error,
            success: success,
            alert: alert
        };

        return self;
    };
     
    /**
     * Плагин для работы с уведомлениями
     * 
     * @param  string|object opt  либо имя функции, либо json с параметрами
     * @param  object opt2 json с параметрами
     *
     * возвращает либо сам объект для которого вызвали плагин,
     * либо значение которая возвращтит одна из вызванных функций
     * 
     * @notes 
     * Тут ничего не трогаем
     */
    $.fn.notifications = function (opt, opt2) {

        var result = this;

        var func = function () {
            var object;
            
            if (!$(this).data('notifications')) {
                object = new Notification(this, _.cloneDeep($.extend(true, defaultOptions, opt)));

                object.init();

                $(this).data('notifications', object);
            } else {
                object = $(this).data('notifications');
            }

            // для случаев когда нужно вызвать функцию
            if ($.type(opt) === 'string' && object[opt] !== undefined && $.isFunction(object[opt])) {
                result = object[opt](opt2);
            }
        };

        func.call(document, opt, opt2);

        return result;

    };

    var defaultOptions = {
        // hide: false,
    };

    $.fn.notifications.defaultOptions = defaultOptions;

}(jQuery));